// Polyfills for IE11
import 'core-js/fn/promise';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/includes';
import 'core-js/fn/object/assign';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/string/includes';
import 'core-js/es6/map';
import 'core-js/fn/function/name';
import 'core-js/fn/object/entries';
import 'core-js/fn/typed';
import 'whatwg-fetch';

if (!window.Intl) {
  /* eslint-disable global-require */
  require('intl');
  require('intl/locale-data/jsonp/en');
  require('intl/locale-data/jsonp/ja');
  require('intl/locale-data/jsonp/en-AU');
}
